import { RouteObject } from 'react-router-dom'

export enum IntakeSubroutes {
  VEHICLE_SELECT = 'customer/:customerId',
  CANNED_JOB_SELECT = 'customer/:customerId/vehicle/:vehicleId',
  CREATE_CUSTOMER = 'new-customer',
  CREATE_VEHICLE = 'customer/:customerId/new-vehicle',
  FINISHED = 'finished',
  VERIFICATION = 'customer/:customerId/verify'
}

export enum AppRoutes {
  WORK_IN_PROGRESS = '/',
  REPORTS = '/reports',
  LOGIN = '/login',
  DVI_LOGIN = '/dvi/login',
  DVI_SIGNUP = '/dvi/signup',
  SIGNUP = '/signup',
  PASSWORD_RESET = '/password-reset',
  DVI_PASSWORD_RESET = '/dvi/password-reset',
  CUSTOMER_RELATIONSHIP_MANAGEMENT = '/crm',
  CUSTOMERS = '/customers',
  NEW_CUSTOMER = '/customers/new',
  CUSTOMER_DETAILS = '/customers/:id',
  SETTINGS = '/settings/:settingsType',
  SETTINGS_PERSONAL = '/settings/personal',
  SETTINGS_SHOP = '/settings/shop',
  SETTINGS_TEAM = '/settings/team',
  SETTINGS_VENDOR = '/settings/vendor',
  SETTINGS_PRICING = '/settings/pricing',
  SETTINGS_BILLING = '/settings/billing',
  SETTINGS_SCHEDULING = '/settings/scheduling',
  SETTINGS_ADMIN = '/settings/admin',
  SETTINGS_CANNED_JOBS = '/settings/canned-jobs',
  SETTINGS_CANNED_JOB_DETAIL = '/settings/canned-jobs/:id',
  SETTINGS_CANNED_JOB_NEW = '/settings/canned-jobs/new',
  VEHICLES = '/vehicles',
  VEHICLE_DETAILS = '/vehicles/:id',
  APPOINTMENTS = '/appointments',
  MANAGE_APPOINTMENTS = '/appointments/:id',
  APPOINTMENT_SCHEDULE = '/appointment-schedule/:shopId',
  APPOINTMENT_SCHEDULE_FINISHED = `${AppRoutes.APPOINTMENT_SCHEDULE}/${IntakeSubroutes.FINISHED}`,
  ESTIMATES = '/estimates',
  ESTIMATE_DETAILS = '/estimates/:id',
  AUTHORIZATION_PDF = '/authorization-pdf/:id',
  WORK_ORDER_PDF = '/work-order-pdf/:id',
  INVOICE_PDF_CUSTOMER = '/invoice-pdf-customer/:id',
  INVOICE_PDF_MERCHANT = '/invoice-pdf-merchant/:id',
  NEW_ESTIMATE = '/estimates/new',
  REPAIR_ORDER_HISTORY = '/history',
  PART_LOOKUP = '/part-lookup.html',
  PART_LOOKUP_DETAIL = '/partlookup',
  INVENTORY = '/inventory',
  INVENTORY_CORE = '/inventory/core',
  INVENTORY_RETURN = '/inventory/return',
  INVENTORY_PART = '/inventory/:id',
  INVENTORY_EXPENSES = '/inventory/expenses',
  TIME_CLOCK = '/time-clock',
  TIME_CLOCK_USER = '/time-clock/user/:id',
  TIME_CLOCK_LABOR_REPORT = '/time-clock/labor-report',
  TIME_CLOCK_REPAIR_ORDER_REPORT = '/time-clock/repair-order-report',
  DVI_HOME = '/dvi',
  DVI_JOBS = '/dvi/jobs',
  DVI_SINGLE_JOB = '/dvi/jobs/:id',
  DVI_PROFILE = '/dvi/profile',
  DVI_INSPECTIONS = '/dvi/inspections',
  DVI_SINGLE_INSPECTION_SUMMARY = '/dvi/inspections/:id',
  DVI_SINGLE_INSPECTION = '/dvi/inspections/:id/details',
  CHAT = '/conversations',
  SINGLE_INSPECTION = '/inspections/:id',
  INSPECTION_RESULTS = '/inspection-results/:apiKey',
  INSPECTION_RESULTS_RAW = '/inspection-results/',
  HELP_WEBSITE = 'https://help.intuneauto.com',
  PAYMENT_WEBSITE = 'https://intuneauto.payments-dashboard.com/',
  PAYMENT_PORTAL = '/payment-portal/:apiKey',
  /*
   * UI will not try to retrieve or display inspection information for the API key.
   * Generally meant for the case of API keys that have no associated inspection,
   * although it should also work for API keys that *are* associated with an inspection.
   */
  CUSTOMER_PORTAL = '/customer-portal/:apiKey',
  CUSTOMER_PORTAL_INVOICE = '/customer-portal/:apiKey/invoice',
  COUNTER_SALES = '/counter-sales',
  COUNTER_SALES_HISTORY = '/counter-sales/history',
  CUSTOMER_FEEDBACK_PAGE = '/feedback/:apiKey',
  EXPENSE_CARD = '/card',
  REPAIR_LINK_PART_LOOKUP = '/repair-link-part-lookup.html',
  REPAIR_LINK_LOGIN_FORM = '/repair-link-login-form.html',
  INTAKE = '/intake/:shopId',
  INTAKE_VEHICLE_SELECT = `${AppRoutes.INTAKE}/${IntakeSubroutes.VEHICLE_SELECT}`,
  INTAKE_CANNED_JOB_SELECT = `${AppRoutes.INTAKE}/${IntakeSubroutes.CANNED_JOB_SELECT}`,
  INTAKE_CREATE_CUSTOMER = `${AppRoutes.INTAKE}/${IntakeSubroutes.CREATE_CUSTOMER}`,
  INTAKE_CREATE_VEHICLE = `${AppRoutes.INTAKE}/${IntakeSubroutes.CREATE_VEHICLE}`,
  INTAKE_FINISHED = `${AppRoutes.INTAKE}/${IntakeSubroutes.FINISHED}`,
  INTAKE_VERIFICATION = `${AppRoutes.INTAKE}/${IntakeSubroutes.VERIFICATION}`
}

export enum RouteFragments {
  SETTINGS_PERSONAL = 'personal',
  SETTINGS_SHOP = 'shop',
  SETTINGS_TEAM = 'team',
  SETTINGS_VENDOR = 'vendor',
  SETTINGS_PRICING = 'pricing',
  SETTINGS_BILLING = 'billing',
  SETTINGS_SCHEDULING = 'scheduling',
  SETTINGS_ADMIN = 'admin',
  SETTINGS_CANNED_JOBS = 'canned-jobs',
  SETTINGS_REPAIR_ORDER_STATUS = 'repair-order-status',
  SETTINGS_DOCUMENTS = 'documents',
  SETTINGS_INSPECTIONS = 'inspections',
  SETTINGS_CALENDAR = 'calendar',
  SETTINGS_PRODUCTIVITY = 'productivity'
}

export const AUTH_ROUTES = [
  AppRoutes.LOGIN,
  AppRoutes.SIGNUP,
  AppRoutes.PASSWORD_RESET
]

export const PARTSTECH_REDIRECT_URL = `${window.location.origin}/partstech-callback.html`
export const REPAIR_LINK_REDIRECT_URL = `${window.location.origin}/repair-link-callback.html`

const HIDE_HUBSPOT_CHAT_PATHS: AppRoutes[] = [
  AppRoutes.DVI_HOME,
  AppRoutes.DVI_JOBS,
  AppRoutes.DVI_SINGLE_JOB,
  AppRoutes.DVI_PROFILE,
  AppRoutes.DVI_INSPECTIONS,
  AppRoutes.DVI_SINGLE_INSPECTION_SUMMARY,
  AppRoutes.DVI_SINGLE_INSPECTION,
  AppRoutes.DVI_LOGIN,
  AppRoutes.DVI_SIGNUP,
  AppRoutes.DVI_PASSWORD_RESET,
  AppRoutes.INSPECTION_RESULTS,
  AppRoutes.AUTHORIZATION_PDF,
  AppRoutes.INVOICE_PDF_CUSTOMER,
  AppRoutes.INVOICE_PDF_MERCHANT,
  AppRoutes.WORK_ORDER_PDF,
  AppRoutes.PAYMENT_PORTAL,
  AppRoutes.CUSTOMER_PORTAL
]

export const HIDE_HUBSPOT_CHAT_ROUTES: RouteObject[] =
  HIDE_HUBSPOT_CHAT_PATHS.map((path) => ({
    path
  }))

import { RepairPaymentInfoViaEnum, UpdatePaymentTypesTypeEnum } from '../../sdk'

export const paymentTypeDisplayNames: Record<
  RepairPaymentInfoViaEnum | UpdatePaymentTypesTypeEnum,
  string
> = {
  [RepairPaymentInfoViaEnum.CreditDebitCard]: 'Credit or Debit Card',
  [RepairPaymentInfoViaEnum.Cash]: 'Cash',
  [RepairPaymentInfoViaEnum.Check]: 'Check',
  [RepairPaymentInfoViaEnum.Finix]: 'InTune POS',
  [RepairPaymentInfoViaEnum.Credit]: 'Accounts Receivable',
  [RepairPaymentInfoViaEnum.Visa]: 'External Credit Card - Visa',
  [RepairPaymentInfoViaEnum.Mastercard]: 'External Credit Card - Master Card',
  [RepairPaymentInfoViaEnum.Discover]: 'External Credit Card - Discover',
  [RepairPaymentInfoViaEnum.AmericanExpress]:
    'External Credit Card - American Express',
  [UpdatePaymentTypesTypeEnum.CreditDebitCard]: 'Credit or Debit Card',
  [UpdatePaymentTypesTypeEnum.Cash]: 'Cash',
  [UpdatePaymentTypesTypeEnum.Check]: 'Check',
  [UpdatePaymentTypesTypeEnum.Finix]: 'InTune POS',
  [UpdatePaymentTypesTypeEnum.Credit]: 'Accounts Receivable',
  [UpdatePaymentTypesTypeEnum.Visa]: 'External Credit Card - Visa',
  [UpdatePaymentTypesTypeEnum.Mastercard]: 'External Credit Card - Mastercard',
  [UpdatePaymentTypesTypeEnum.Discover]: 'External Credit Card - Discover',
  [UpdatePaymentTypesTypeEnum.AmericanExpress]:
    'External Credit Card - American Express'
}

export enum AdditionalPaymentOption {
  FinixCardPresent = 'finix_card_present',
  FinixCardNotPresent = 'finix_card_not_present',
  TextToPay = 'text_to_pay'
}

type SelectableViaOption = Exclude<
  RepairPaymentInfoViaEnum,
  | typeof RepairPaymentInfoViaEnum.Finix
  | typeof RepairPaymentInfoViaEnum.CreditDebitCard
>

export type PaymentOption = SelectableViaOption | AdditionalPaymentOption

const selectableViaOptions: SelectableViaOption[] = Object.values(
  RepairPaymentInfoViaEnum
).filter(
  (via) =>
    via !== RepairPaymentInfoViaEnum.CreditDebitCard &&
    via !== RepairPaymentInfoViaEnum.Finix
) as SelectableViaOption[]

const additionalPaymentOptions: AdditionalPaymentOption[] = Object.values(
  AdditionalPaymentOption
)

export const PAYMENT_OPTIONS: PaymentOption[] = (
  selectableViaOptions as PaymentOption[]
).concat(additionalPaymentOptions) as PaymentOption[]

export const getPaymentOptionDisplayName: (option: PaymentOption) => string = (
  option
) => {
  if ((Object.values(RepairPaymentInfoViaEnum) as string[]).includes(option)) {
    return paymentTypeDisplayNames[option as RepairPaymentInfoViaEnum]
  } else {
    function getAdditionalPaymentOptionDisplayName(suffix: string) {
      return `${
        paymentTypeDisplayNames[RepairPaymentInfoViaEnum.Finix]
      } - ${suffix}`
    }

    switch (option as AdditionalPaymentOption) {
      case AdditionalPaymentOption.FinixCardNotPresent:
        return getAdditionalPaymentOptionDisplayName('Manual Card Input')
      case AdditionalPaymentOption.FinixCardPresent:
        return getAdditionalPaymentOptionDisplayName('Card Present')
      default:
        return getAdditionalPaymentOptionDisplayName('Text-to-Pay')
    }
  }
}
